<template>
  <div class="pictime-product-sheet">
    <slot name="title">
      <SfHeading
        :level="1"
        :title="title"
        class="sf-heading--no-underline sf-heading--left pictime-product-sheet__title"
      />
    </slot>
    <slot name="short-description">
      <span class="pictime-product-sheet__description">
        {{ description }}
      </span>
    </slot>
    <slot name="documents">
      <div
        class="pictime-product-sheet__documents"
        v-if="technicalDataSheet && technicalDataSheet[1]"
      >
        <a :href="technicalDataSheet[1]" target="_blank">
          <span class="link-title documents__link pictime-product-sheet__documents__link">VOIR LA FICHE TECHNIQUE</span>
        </a>
      </div>
    </slot>
    <slot name="images">
      <SfLoader
        :class="{ 'loading--product-gallery': productLoading }"
        :loading="productLoading"
        class="pictime-product-sheet__images"
      >
        <PictimeGallery
          :enable-zoom="false"
          :imageHeight="imageHeight"
          :imageWidth="imageWidth"
          :images="productGallery"
          class="pictime-product-sheet__images--gallery"
        />
      </SfLoader>
    </slot>
    <slot name="assets">
      <SfList class="pictime-product-sheet__assets">
        <SfListItem
          v-for="(picto, index) in assets"
          v-if="picto"
          :key="index"
          class="pictime-product-sheet__assets--asset-picto"
        >
          <PictimeSfImage
            :alt="picto.asset_label"
            :src="picto.file_path"
            height="50"
          />
        </SfListItem>
      </SfList>
    </slot>
    <slot name="tester">
      <TesteurCouleur v-if="peinturesPro" :link="testerLink" :is-desktop="true"/>
    </slot>
  </div>
</template>

<script>
import { SfHeading, SfLink, SfList, SfLoader, } from "@storefront-ui/vue";
import PictimeGallery from '~/components/molecules/PictimeGallery';
import imgPlaceholder from "~/static/thankyou/visuel-produit-generique.svg";
import TesteurCouleur from "~/modules/catalog/product/components/TesteurCouleur.vue";
import PictimeSfImage from "~/components/molecules/PictimeSfImage.vue";

export default {
  name: "PictimeProductSheet",
  components: {
    PictimeSfImage,
    TesteurCouleur,
    SfHeading,
    SfLoader,
    SfList,
    SfLink,
    PictimeGallery,
  },
  props: {
    description: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    productLoading: {
      type: Boolean,
      default: false,
    },
    productGallery: {
      type: [Array, Object, String],
      default: "",
    },
    assets: {
      type: [Array, Object, String],
      default: [],
    },
    peinturesPro: {
      type: Boolean,
      default: false,
    },
    testerLink: {
      type: String,
      default: "",
    },
    documents: {
      type: Array,
      default: {}
    }
  },
  data() {
    return {
      desktopMin: 1024,
      imageWidth: '128',
      imageHeight: '128',
      imgPlaceholder
    };
  },
  computed: {
    technicalDataSheet() {
      return this.documents?.find(doc => doc[0] === 'technical_data_sheet_pdf')
    }
  },
  mounted() {
    this.imageWidth = window.innerWidth > this.desktopMin - 1 ? '300' : '128';
    this.imageHeight = window.innerWidth > this.desktopMin - 1 ? '300' : '128';
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/theme/default/scss/components/organisms/pictime-product-sheet.scss";
</style>
