<template>
  <div class="sf-gallery">
    <SvgImage
      v-if="this.images.length > 1"
      class="glide__arrow glide__arrow--left"
      height="20"
      icon="Icon_arrow_left"
      width="20"
    />
    <div class="sf-gallery__stage">
      <div ref="glide" class="glide">
        <div class="glide__track" data-glide-el="track">
          <ul class="glide__slides">
            <li
              v-for="(picture, index) in images"
              :key="'slide-' + index"
              class="glide__slide"
            >
              <SfImage
                ref="sfGalleryBigImage"
                :alt="picture.alt"
                :class="{ 'sf-gallery__big-image--has-zoom': enableZoom }"
                :height="imageHeight"
                :placeholder="imgPlaceholder"
                :src="picture.desktop.url"
                :width="imageWidth"
                class="sf-gallery__big-image"
                @click="$emit('click:stage', { picture, index })"
              />
            </li>
          </ul>
        </div>
      </div>
      <transition name="sf-fade">
        <div
          ref="outSide"
          :class="{
            'display-none':
              !outsideZoom || !pictureSelected || (!outsideZoom && !enableZoom),
          }"
          :style="{ width: `${imageWidth}px`, height: `${imageHeight}px` }"
        >
          <SfImage
            ref="imgZoom"
            :alt="pictureSelected.alt"
            :height="imageHeight"
            :lazy="false"
            :placeholder="imgPlaceholder"
            :src="pictureSelectedUrl"
            :width="imageWidth"
            class="sf-gallery__zoom"
          />
        </div>
      </transition>
    </div>
    <SvgImage
      v-if="this.images.length > 1"
      class="glide__arrow glide__arrow--right"
      height="20"
      icon="Icon_arrow_right"
      width="20"
    />
  </div>
</template>
<script>
import Glide from "@glidejs/glide";
import { SfButton, SfImage, } from '@storefront-ui/vue';
import SvgImage from '~/components/General/SvgImage.vue';
import imgPlaceholder from "~/static/thankyou/visuel-produit-generique.svg";

export default {
  name: "PictimeGallery",
  components: {
    SfImage,
    SfButton,
    SvgImage,
  },
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    imageWidth: {
      type: [Number, String],
      default: 128,
    },
    imageHeight: {
      type: [Number, String],
      default: 128,
    },
    current: {
      type: Number,
      default: 1,
    },
    sliderOptions: {
      type: Object,
      default() {
        return {
          type: "carousel",
          autoplay: false,
          rewind: true,
          gap: 0,
          dragThreshold: false,
          swipeThreshold: false,
        };
      },
    },
    outsideZoom: {
      type: Boolean,
      default: false,
    },
    enableZoom: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      positionStatic: {},
      eventHover: {},
      pictureSelected: { alt: "" },
      glide: null,
      activeIndex: this.current - 1,
      style: "",
      imgPlaceholder
    };
  },
  computed: {
    mapPictures() {
      // map images to handle picture tags with SfImage
      return this.images.map(({ desktop, big }) => ({
        mobile: desktop,
        desktop: big,
      }));
    },
    updatedSliderOptions() {
      return { ...this.sliderOptions, startAt: this.activeIndex };
    },
    pictureSelectedUrl() {
      const { zoom, big, desktop } = this.pictureSelected;
      const definedPicture         = zoom || big || desktop;
      return definedPicture ? definedPicture.url : "";
    },
  },
  mounted() {
    this.$nextTick(() => {
      // handle slider with swipe and transitions with Glide.js
      // https://glidejs.com/docs/
      if (this.images.length < 1) return;
      const glide = new Glide(this.$refs.glide, this.updatedSliderOptions);
      glide.on("run", () => {
        this.go(glide.index);
      });
      const forward  = document.querySelector('.glide__arrow--right');
      const backward = document.querySelector('.glide__arrow--left');
      forward?.addEventListener('click', function () {
        glide.go('>');
      });

      backward?.addEventListener('click', function () {
        glide.go('<');
      });
      glide.mount();
      this.glide = glide;
    });
  },
  beforeDestroy() {
    if (this.glide) {
      this.glide.destroy();
    }
  },
  methods: {
    positionObject(index) {
      if (this.$refs.sfGalleryBigImage) {
        if (this.outsideZoom) {
          return this.$refs.glide.getBoundingClientRect();
        } else {
          return this.$refs.sfGalleryBigImage[
            index
            ].$el.getBoundingClientRect();
        }
      }
      return "";
    },
    go(index) {
      if (!this.glide) return;
      this.activeIndex = index;
      /**
       * Event for current image change (`v-model`)
       * @type {Event}
       */
      this.$emit("click", index + 1);
      if (this.glide) {
        this.glide.go(`=${index}`);
      }
    },
  },
};
</script>
<style lang="scss">
@import "~@storefront-ui/shared/styles/components/molecules/SfGallery.scss";
@import "@/assets/theme/default/scss/components/molecules/pictime-gallery.scss";
</style>
