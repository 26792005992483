

















































































































import LazyHydrate from 'vue-lazy-hydration';
import { SfButton, SfGallery, SfHeading, SfLoader, SfPrice, SfRating, } from '@storefront-ui/vue';
import { computed, defineComponent, PropType, ref, toRef, } from '@nuxtjs/composition-api';

import { getName as getProductName, getSwatchData as getProductSwatchData, } from '~/modules/catalog/product/getters/productGetters';

import { getAverageRating, getTotalReviews, } from '~/modules/review/getters/reviewGetters';

import { useCart } from '~/modules/checkout/composables/useCart';
import GroupedProductSelector from '~/modules/catalog/product/components/product-types/grouped/GroupedProductSelector.vue';
import SvgImage from '~/components/General/SvgImage.vue';
import HTMLContent from '~/components/HTMLContent.vue';
import { useUser } from '~/modules/customer/composables/useUser';
import { getGroupedProductPriceCommand } from '~/modules/catalog/pricing/getGroupedProductPriceCommand';
import { Product } from '~/modules/catalog/product/types';
import ProductTabs from '~/modules/catalog/product/components/tabs/ProductTabs.vue';
import { useProductGallery } from '~/modules/catalog/product/composables/useProductGallery';
import { TabsConfig, useProductTabs } from '~/modules/catalog/product/composables/useProductTabs';
import PictimeProductSheet from '../../../../../../components/organisms/PictimeProductSheet.vue';

export default defineComponent({
  name: 'GroupedProduct',
  components: {
    GroupedProductSelector,
    HTMLContent,
    LazyHydrate,
    SfButton,
    SfGallery,
    SfHeading,
    SfLoader,
    SfPrice,
    SfRating,
    SvgImage,
    ProductTabs,
    PictimeProductSheet,
  },
  transition: 'fade',
  props: {
    product: {
      type: [Object, null] as PropType<Product>,
      default: null,
    },
    productAssets: {
      type: Array,
      default: () => ['']
    },
    isPeinturePro: {
      type: Boolean,
      default: false
    },
    productType: {
      type: String,
      default: 'Peintures pro'
    },
    documentsCount: {
      type: Object,
      default: {}
    },
    isFetching: {
      type: Boolean,
      default: true,
    },
    documents: {
      type: Array,
      default: {}
    }
  },
  setup(props) {
    const qty = ref(1);
    const product = toRef(props, 'product');
    const { addItem, canAddToCart } = useCart();
    const { productGallery, imageSizes } = useProductGallery(product);
    const { activeTab, setActiveTab, openNewReviewTab } = useProductTabs();

    const { isAuthenticated } = useUser();
    const basePrice = ref(0);
    const openTab = ref(1);

    const productShortDescription = computed(
      () => props.product?.short_description?.html || '',
    );

    const productPrice = computed(() => getGroupedProductPriceCommand(props.product));
    const productSpecialPrice = 0;
    const totalReviews = computed(() => getTotalReviews(props.product));
    const averageRating = computed(() => getAverageRating(props.product));

    const getTaxe = (isAuth) => {
      return !isAuth ? ' TTC' : ' HT'
    }

    return {
      addItem,
      averageRating,
      basePrice,
      canAddToCart,
      isAuthenticated,
      openTab,
      productGallery,
      getProductName,
      getProductSwatchData,
      productPrice,
      productShortDescription,
      productSpecialPrice,
      qty,
      totalReviews,
      imageSizes,
      setActiveTab,
      openNewReviewTab,
      activeTab,
      TabsConfig,
      getTaxe
    };
  },
});
